import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { gsap } from 'gsap'

const textureLoader = new THREE.TextureLoader()
const texture = textureLoader.load('/tex/stars.jpeg')

const moon = textureLoader.load('/tex/2k_moon.jpg')
const sun = textureLoader.load('/tex/2k_sun.jpg')
const earth = textureLoader.load('/tex/2k_earth_nightmap.jpg')
const mars = textureLoader.load('/tex/2k_mars.jpg')

var mat = [
    new THREE.MeshStandardMaterial({map: moon}),
    new THREE.MeshStandardMaterial({map: sun}),
    new THREE.MeshStandardMaterial({map: mars}),
    new THREE.MeshStandardMaterial({map: earth})
];

/** to do
 *    Kamera tiefer
 */


// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

// Overlay
const overlayGeometry = new THREE.PlaneGeometry(2, 2, 1, 1)
const overlayMaterial = new THREE.ShaderMaterial({
    transparent: true,
    uniforms:{
        uAlpha: { value: 1}
    },
    vertexShader: `
        void main()
        {
            gl_Position = vec4(position, 1.0);
        }
    `,
    fragmentShader: `
        uniform float uAlpha;
        void main()
        {
            gl_FragColor = vec4(0.0, 0.0, 0.0, uAlpha);
        }
    `
})
const overlay = new THREE.Mesh(overlayGeometry, overlayMaterial)
scene.add(overlay)


// Object
const geometry = new THREE.BoxGeometry(1, 1, 1)
const material1 = new THREE.MeshBasicMaterial({ color: 'grey' })
const mesh = new THREE.Mesh(geometry, material1)
//scene.add(mesh)

const geometry2 = new THREE.BoxGeometry(300, 0.1, 200)
//const material2 = new THREE.MeshBasicMaterial({ color: 0xFFA500})
const material2 = new THREE.MeshPhongMaterial()
material2.color = new THREE.Color(0xFFA500)
const mesh2 = new THREE.Mesh(geometry2, material2)
mesh2.position.y = -0.51
//scene.add(mesh2)
mesh2.receiveShadow = true

const geometry3 = new THREE.SphereGeometry( 400, 60, 40 );
geometry3.scale( - 1, 1, 1 );
const material3 = new THREE.MeshStandardMaterial({map: texture})
const mesh3 = new THREE.Mesh(geometry3, material3)
scene.add(mesh3)


const material5  = new THREE.MeshBasicMaterial()
for(let i=1; i<4; i++){
    const geometry5  = new THREE.TorusGeometry( i*20 +15, 0.1, 16, 100 );
    const meshTorus     = new THREE.Mesh(geometry5, material5)
    meshTorus.rotation.x = Math.PI/2
    scene.add(meshTorus)
}

var pivots = [
    new THREE.Group(),
    new THREE.Group(),
    new THREE.Group()
];
scene.add( pivots[0],pivots[1],pivots[2] );

var planets = [];

for(let i=1; i<4; i++){
    const geometry6      = new THREE.SphereGeometry(3,30,30);
    //const geometry6  = new THREE.TorusGeometry( 2, 0.1, 16, 100 );
    planets[i-1] = new THREE.Mesh(geometry6, mat[i-1])
    planets[i-1].position.set(i*20 +15,0,0)
    scene.add(planets[i-1])
    pivots[i-1].add(planets[i-1]);
}

var zufall = [
    Math.PI*2*Math.random(),
    Math.PI*2*Math.random(),
    Math.PI*2*Math.random()
]



// Light
const ambientLight = new THREE.AmbientLight(0xffffff, 0.2)
scene.add(ambientLight)

const pointLight = new THREE.PointLight(0xffffff, 1.2)
// pointLight.position.x = 1
// pointLight.position.y = 4
// pointLight.position.z = 1
scene.add(pointLight)
pointLight.castShadow = true

// Sizes
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}
const zoomFaktor = 3;

// camera
const aspectRatio = sizes.width / sizes.height
const camera = new THREE.PerspectiveCamera(45, sizes.width / sizes.height, 1, 1000)
//const camera = new THREE.OrthographicCamera(-zoomFaktor * aspectRatio,zoomFaktor * aspectRatio,zoomFaktor,-zoomFaktor, 0.1, 1000)
camera.position.z = 5
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true
//controls.maxPolarAngle = Math.PI/4;
controls.screenSpacePanning = false;
controls.dampingFactor = 0.1;
controls.enablePan = false;
controls.rotateSpeed = 0.4;
//controls.enableRotate = false
//controls.maxZoom = 1;   //wie nah
//controls.minZoom = 0.2; // wie weit, kleiner = weiter
controls.minDistance = 25;
controls.maxDistance = 120;

// LOAD
const loadingBarElement = document.querySelector('.loading-bar')

const loadingManager = new THREE.LoadingManager(
    // Loaded
    () =>
    {
        window.setTimeout(() =>
        {
            gsap.to(overlayMaterial.uniforms.uAlpha, { duration: 3, value: 0, delay: 1 })

            loadingBarElement.classList.add('ended')
            loadingBarElement.style.transform = ''
        }, 500)
        controls.minDistance = 7;
    },

    // Progress
    (itemUrl, itemsLoaded, itemsTotal) =>
    {
        const progressRatio = itemsLoaded / itemsTotal
        loadingBarElement.style.transform = `scaleX(${progressRatio})`
    }
)
const gltfLoader = new GLTFLoader(loadingManager)



//const material4 = new THREE.MeshNormalMaterial()
const material4 = new THREE.MeshBasicMaterial({color: 0xffdd00})
//material4.wireframe = true
//material4.side = THREE.DoubleSide
var loadID = 0
var pivot = new THREE.Group();
scene.add( pivot );

gltfLoader.load(
    '/models/Zitrone.glb',
    (gltf) =>
    {
        console.log('success')
        console.log(gltf.scene.children[0])
        loadID = gltf.scene.id;
        //console.log( gltf.scene.id)
        gltf.scene.children[0].material = material4
        gltf.scene.children[0].scale.set(0.4,0.4,0.4)
        
        // zentrieren
        var box = new THREE.Box3().setFromObject( gltf.scene );
        var center = box.getCenter( new THREE.Vector3() );
        
        gltf.scene.position.x += ( gltf.scene.position.x - center.x );
        //gltf.scene.position.y += ( gltf.scene.position.y - center.y );
        gltf.scene.position.z += ( gltf.scene.position.z - center.z );
        
        //gltf.scene.position.multiplyScalar( - 1 );
        pivot.add( gltf.scene );

        //scene.add(gltf.scene)
        // scene.getObjectById(loadID).castShadow = true 
        // scene.getObjectById(loadID).position.y = 1 
        
    }
)


/*
gltfLoader.load(
    '/models/monkey.gltf',
    (gltf) =>
    {
        console.log('success')
        gltf.scene.children[2].material = material4
        loadID = gltf.scene.children[2].id;
        scene.add(gltf.scene.children[2])
        scene.getObjectById(loadID).castShadow = true 
        scene.getObjectById(loadID).position.y = 1 
        
    }
)

*/


window.addEventListener('resize', () => 
{
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    camera.aspect = sizes.width / sizes.height
    // aspectRatio = sizes.width / sizes.height
    // camera.left = -zoomFaktor * aspectRatio
    // camera.right= zoomFaktor * aspectRatio
    // camera.top  = zoomFaktor
    // camera.bottom = -zoomFaktor
    camera.updateProjectionMatrix()

    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

window.addEventListener('dblclick', () => 
{
    if(!document.fullscreenElement)
    {
        canvas.requestFullscreen()
    }
    else
    {
        document.exitFullscreen()
    } 
})

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
renderer.shadowMap.enabled = true



/**
 * Animate
 */
const clock = new THREE.Clock()


const tick = () =>
{   
    const elapsedTime = clock.getElapsedTime()
    pivot.rotation.y = elapsedTime * Math.PI *2 *0.005

    pivots[0].rotation.y = elapsedTime * Math.PI *2 *0.007 + zufall[0]
    pivots[1].rotation.y = elapsedTime * Math.PI *2 *0.003 + zufall[1]
    pivots[2].rotation.y = elapsedTime * Math.PI *2 *0.001 + zufall[2]

    planets[0].rotation.y = elapsedTime * Math.PI *2 *0.01
    planets[1].rotation.y = elapsedTime * Math.PI *2 *0.01
    planets[2].rotation.y = elapsedTime * Math.PI *2 *0.01

    if(loadID!=0){
        //scene.getObjectById(loadID).rotation.y = elapsedTime * Math.PI *2 *0.01
        //scene.getObjectById(loadID).rotateOnAxis(center, elapsedTime * Math.PI *2 *0.01)
        //scene.getObjectById(loadID).rotation.y = elapsedTime * Math.PI *2 *0.01
    //console.log("JOOOOO")
        //console.log(scene.getObjectByProperty("uuid", "DD1C1258-1956-4217-81FB-088E99C52954"))
    }
    //scene.children[5].rotation.y = elapsedTime * Math.PI *2 *0.01
    //mesh.rotation.y = elapsedTime * Math.PI *2 *0.01
    // Update controls
    controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()